/** @jsx jsx **/
// @ts-ignore
import { jsx, Grid, Box, Heading, Text, Container, BaseStyles } from 'theme-ui'
import Layout from '~/components/layout'
import { Button } from '~/components/button'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { space } from '~/gatsby-plugin-theme-ui'
import MarkdownIt from '~/components/MarkdownIt'
import SanitizeHtml from '~/components/sanitize-html'
import { GatsbyImageType } from '~/@types/models'
import Banner from '~/componentsV2/banner'
import useMediaQuery from '~/hooks/use-media-query'
import Img from 'gatsby-image'

type DiscountItemType = {
  buttonLabel: string
  buttonLink: string
  title: string
  background: GatsbyImageType
}

type QueryResult = {
  pageData: {
    frontmatter: {
      title: string
      note: string
      discountCodeMessage: {
        message: string
        enabled: boolean
      }
      salesCollections: DiscountItemType[]
      image: GatsbyImageType
      bannerText: string
    }
    html: string
  }
}

const query = graphql`
  query {
    pageData: markdownRemark(frontmatter: { templateKey: { eq: "black-friday" } }) {
      frontmatter {
        title
        note
        discountCodeMessage {
          message
          enabled
        }
        salesCollections {
          buttonLabel
          buttonLink
          title
          background {
            ...heroImage
          }
        }
        image {
          ...heroImage
        }
        bannerText
      }
      html
    }
  }
`
const SaleItem = ({ item }: { item: DiscountItemType }) => {
  const { title, buttonLink, buttonLabel, background } = item
  return (
    <Box sx={{ position: 'relative', textDecoration: 'none', color: 'white' }}>
      <Box
        sx={{
          position: 'absolute',
          zIndex: 1,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'hidden',
        }}
      >
        <Img
          fluid={{ ...background.childImageSharp.fluid }}
          sx={{
            mb: 'md1',
          }}
        />
      </Box>
      <Grid
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          minHeight: [180, null, 285],
          p: [space.md, null, space.md2],
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          position: 'relative',
          zIndex: 2,
        }}
      >
        <Box>
          <Heading
            as="h3"
            variant="title2"
            mb={space.md}
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
              textDecoration: 'none',
              color: 'white',
            }}
          >
            {title}
          </Heading>
          <Button
            as="link"
            label={buttonLabel}
            to={buttonLink}
            sx={{
              border: 'none',
              backgroundColor: 'transparent',
              color: 'white',
              '& span': {
                color: 'white',
                textDecoration: 'underline',
              },
            }}
          />
        </Box>
      </Grid>
    </Box>
  )
}

const SalePage = () => {
  const {
    pageData: {
      frontmatter: { title, discountCodeMessage, salesCollections, image, bannerText },
      html: body,
    },
  } = useStaticQuery<QueryResult>(query)

  const isMobile = useMediaQuery({
    query: breakpoints => `(max-width: ${breakpoints[2]})`,
  })

  return (
    <Layout headerVariant="default" title={title}>
      <Container>
        <Banner url={image.childImageSharp.fluid} overlay="black">
          <Text
            dangerouslySetInnerHTML={{ __html: bannerText }}
            sx={{
              maxWidth: 1300,
              zIndex: 3,
              width: '100%',
              position: 'absolute',
              top: ['30%', null, '15%'],
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: 'bgDarkBlue',
              fontFamily: 'heading',
              fontWeight: 700,
              fontSize: isMobile ? 20 : 45,
              textAlign: 'center',
              '& div': {
                fontSize: isMobile ? 16 : 32,
                marginBottom: isMobile ? 5 : 20,
                fontWeight: 'normal',
              },
              '& .small': {
                paddingTop: isMobile ? 5 : 22,
                fontSize: isMobile ? 16 : 20,
                marginBottom: 0,
                fontFamily: 'body',
              },
              '& a': {
                paddingTop: isMobile ? 5 : 22,
                fontSize: isMobile ? 16 : 20,
                marginBottom: 0,
                fontFamily: 'body',
                color: 'bgDarkBlue',
                textDecoration: 'underline',
              },
              '& .underline': {
                marginBottom: 0,
              },
            }}
          />
        </Banner>
        {discountCodeMessage.enabled && (
          <Text
            sx={{
              my: [40, null, 80],
              fontSize: [20, null, 24],
              lineHeight: '38px',
              color: 'muted',
              textAlign: 'center',
              strong: {
                color: 'text',
              },
              px: 24,
            }}
          >
            <BaseStyles>
              <MarkdownIt content={discountCodeMessage.message} />
            </BaseStyles>
          </Text>
        )}
        {salesCollections && (
          <Grid columns={['1fr', null, 'repeat(3, 1fr)']} gap={[32, null, 24]}>
            {salesCollections.map(item => (
              <SaleItem item={item} />
            ))}
          </Grid>
        )}
        <Container
          variant="containerPrimary"
          sx={{
            my: [40, null, 80],
          }}
        >
          <Text
            variant="micro"
            sx={{
              color: 'muted',
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <SanitizeHtml content={body} />
            </Box>
          </Text>
        </Container>
      </Container>
    </Layout>
  )
}

export default SalePage
